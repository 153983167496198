// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "bootstrap"
import Chart from "chart.js/auto";
global.Chart = Chart;
import "../stylesheets/application"
import "../packs/result_chart"
import "../packs/sort_table"

// font-awesome
import '@fortawesome/fontawesome-free/js/all'
import '../css/application.scss'

function unescapeHTML(escapedHtml) {
  const doc = new DOMParser().parseFromString(escapedHtml, 'text/html');
  return doc.documentElement.textContent;
}
global.unescapeHTML = unescapeHTML;

Rails.start()
Turbolinks.start()
ActiveStorage.start()

document.addEventListener('DOMContentLoaded',function() {
  setTimeout(function(){
    document.querySelectorAll('.alert-notice,.alert-alert').forEach((item) => {
      item.classList.add('fadeout');
    });
  }, 2000);

  setTimeout(function(){
    document.querySelectorAll('.alert-notice,.alert-alert').forEach((item) => {
      item.style.display = "none";
    });
  }, 2500);
});
