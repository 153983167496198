function renderRadarChart(id, labels, data1, data2, pointSuffix, displayPoints) {
  if (!pointSuffix) pointSuffix = '';
  const dataSize = JSON.parse(data1).length;

  const canvas = document.getElementById(id);
  if (!canvas) return;
  const ctx = canvas.getContext('2d');

  const options = {
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      }
    },
    scales: {
      r: {
        grid: { // 補助線（目盛の線）
          color: 'white'
        },
        angleLines: { // 軸（放射軸）
          color: 'white'
        },
        min: 0,
        max: 100,
        ticks: {
          stepSize: 20,
          callback: function() { return ''},
          showLabelBackdrop : false,
        },
        pointLabels: { // 軸のラベル
          callback: function(pointLabel, index) {
            let point = `${JSON.parse(data1)[index]}${pointSuffix}`;
            const point2 = `${JSON.parse(data2)[index]}${pointSuffix}`;
            point = `${point} (${point2})`
            if (displayPoints) {
              point = JSON.parse(unescapeHTML(displayPoints))[index];
            }
            if (dataSize > 4 || index % 2 == 0) {
              return pointLabel + ' ' + point;
            } else {
              return [pointLabel, ' ' + point + ' '];
            }
          },
          color: 'white',
          font: {
            size: 12,
            weight: 'bold'
          }
        }
      }
    }
  };

  const data = JSON.parse(data1);
  let borderWidth = 0;
  if (data.filter(value=> value != 0).length < 3) {
    borderWidth = 10;
  }

  const radarChart = new Chart(ctx, {
    type: 'radar',
    data: {
      labels: JSON.parse(unescapeHTML(labels)),
      datasets: [
        {
          data: data,
          backgroundColor: 'rgba(245,255,2,0.61)',
          borderColor: 'rgba(245,255,2,0.61)',
          borderWidth: borderWidth,
          pointStyle: 'circle',
          pointRadius: 0,
          pointBorderColor: '',
          pointBorderWidth: 0,
          pointBackgroundColor: ''
        },
        {
          data: JSON.parse(data2),
          fill: false,
          borderColor: '#BC0A5C',
          borderWidth: 2,
          pointStyle: 'rect',
          pointRadius: 0,
          pointBorderColor: '',
          pointBorderWidth: 0,
          pointBackgroundColor: ''
        }
      ]
    },
    options: options
  });
}

function renderBarChart(id, labels, data, position) {
  const ctx = document.getElementById(id).getContext('2d');

  //const focusosition = JSON.parse(position);
  const colors = [];

  for (let i = 0; i < data.length; i++) {
    if (position && i == position) {
      colors.push('rgba(245, 255, 2, 0.5)');
    } else {
      colors.push('rgba(255, 255, 255, 0.5)');
    }
  }

  var barChart = new Chart(ctx, {
    type: 'bar',
    data: {
      labels: JSON.parse(unescapeHTML(labels)),
      datasets: [
        {
          data: JSON.parse(data),
          backgroundColor: colors,
          borderColor: colors
        }
      ]
    },
    options: {
      responsive: false,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        }
      },
      scales: {
        x: {
          beginAtZero: true,
          ticks: {
            color: 'white',
            font: {
              size: 10
            }
          },
          grid: {
            display: false,
            borderColor: 'white',
            borderWidth: 2
          }
        },
        y: {
          beginAtZero: true,
          ticks: {
            display: false
          },
          grid: {
            drawBorder: false,
            display: false,
          }
        }
      }
    }
  });
}

global.renderRadarChart = renderRadarChart;
global.renderBarChart = renderBarChart;
