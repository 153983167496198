const { arrow } = require("@popperjs/core");

function initSortTable(selector, column, dataFormat) {
  const table = document.querySelector(selector);
  const tbody = table.querySelector('tbody');
  const headerRow = tbody.querySelector('tr:first-child');
  const th = headerRow.querySelector(`th:nth-child(${column})`)

  setSortMark(th, '');
  th.style.cursor = 'pointer';

  th.onclick = function() {
    let order = th.getAttribute('data-sort-order');
    if (!order || order == 'desc') {
      order = 'asc';
    } else {
      order = 'desc'
    }
    th.setAttribute('data-sort-order', order);

    sortTable(selector, column, order, dataFormat)
    setSortMark(th, order)
  }
}

function sortTable(selector, column, order, dataFormat) {
  const table = document.querySelector(selector);
  const tbody = table.querySelector('tbody');

  const headerRow = tbody.querySelector('tr:first-child');
  headerRow.remove();

  const trArray = Array.prototype.slice.call(tbody.querySelectorAll('tr'));

  function compare(tr1, tr2) {
    let value1 = tr1.querySelector(`td:nth-child(${column})`).textContent;
    let value2 = tr2.querySelector(`td:nth-child(${column})`).textContent;

    if (dataFormat == 'percent') {
      value1 = Number(value1.slice(0, -1));
      value2 = Number(value2.slice(0, -1));
    }

    if (value1 > value2) {
      return 1;
    } else if (value1 < value2) {
      return -1;
    }
    return 0;
  }

  trArray.sort(compare);
  if (order == 'desc') trArray.reverse();

  for (let i = 0; i < trArray.length; i++) {
    tbody.appendChild(tbody.removeChild(trArray[i]))
  }

  tbody.prepend(headerRow);
}

function setSortMark(th, order) {
  const sortMark = th.querySelector('.sort-mark');
  if (sortMark) sortMark.remove();

  th.insertAdjacentHTML('beforeend', sortMarkHtml(order));
}

function sortMarkHtml(order) {
  const style = 'display: inline-block;'
  return `
    <div class="sort-mark" style="${style}">
      ${arrowsHtml(order)}
    </div>
  `;
}

function arrowsHtml(order) {
  let arrows = [];
  if (order == 'asc') {
    arrows = ['△', '▼'];
  } else if (order == 'desc') {
    arrows = ['▲', '▽'];
  } else {
    arrows = ['△', '▽'];
  }

  const style = 'font-size: 10px; height: 10px';

  return `
      <div class="arrow" style="${style}">${arrows[0]}</div>
      <div class="arrow" style="${style}">${arrows[1]}</div>
  `;
}

global.initSortTable = initSortTable;
